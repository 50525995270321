import { css } from 'styled-components'
import { overwriteDefaultTheme, withGlobalStyle, mediaQuery } from './'

export const defaultGridOptions = {
  mobile: {
    columns: 6,
    columnWidth: 69,
    gutterWidth: 0,
    maxWidth: 414,
  },
  tablet: {
    columns: 12,
    columnWidth: 120,
    gutterWidth: 0,
    maxWidth: 1440
  },
  desktop: {
    columns: 12,
    columnWidth: 120,
    gutterWidth: 0,
    maxWidth: 1440
  }
}

const themeProject = {
  colors: {
    'background': '#1B1815',
    'text': '#FFFFFF',
    'white': '#FFFFFF',
    'black': '#1B1815'
  },
  ease: 'cubic-bezier(.25, .1, .25, 1)',
  col: {
    mobile: 60,
    desktop: defaultGridOptions.desktop.columnWidth
  }
}

const getRelativeCreator = (mobileValue: number, desktopValue: number) => {
  const VALUES = {
    'mobile': mobileValue,
    'desktop': desktopValue
  }

  return (from: number, type: 'mobile' | 'desktop', custom = false) => {
  return `${(from / VALUES[type]) * 100}${custom ? '%' : 'vw'}`
}}

export const getRawRelative = (from, to) => {
  return `${(from / to) * 100}`
}

export const getRelative = getRelativeCreator(defaultGridOptions.mobile.maxWidth, defaultGridOptions.desktop.maxWidth)


export const getTransition = (duration = .3) => ({
  duration,
  type: 'tween',
  ease: [.25, .1, .25, 1]
})

export function hex2Rgba (hex, alpha) {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const hideScrollbar = () => css`
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 255);
    display: none;
  }
`

export const theme = overwriteDefaultTheme(themeProject);

const extraGlobalCss = `
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  *{ 
    margin: 0;
    padding: 0;
  }
  
  section, article, header, footer, nav, aside, hgroup{
    display: block;
  }

  html{
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    height: 100%;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
  }
  
  img{
    border: none;
    -ms-interpolation-mode: bicubic;
  }
  
  body{
    font-size: 62.5%;
    height: 100%;
    font-family: 'sofia', 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    font-weight: normal; 
    font-style: normal;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
  }
  
  .root{
    height: 100%;
    background: black;
    background-size: cover;
    background-attachment: fixed;
  }
  
  a{
    outline: none;
    text-decoration: none;
  }
  .overlay {
    z-index: 15;
    position:relative;
    height: 100%;
  }
  .video {
    position:absolute;
    width: 100vw;
    height: 100%;
    video {
      object-fit: cover;
    }
  }
  
  .header{
    width: 100%;
    float: left;
    height: 50%;
    display: table;
    
  }
  
  .header > div, .main  > div{
     display: table-cell;
     text-align: center;
     vertical-align: middle;
  }
  
  a.header--down-arrow {
    display: block;
    position: absolute;
    top: calc(100vh - 35px);
    left: 50%;
    width: 30px;
    height: 18px;
    margin: 0 0 0 -15px;
    z-index: 11;
    -webkit-animation: bounce 1.2s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
    animation: bounce 1.2s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
  
    opacity: 0;
    -webkit-transition: opacity .6s 1.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: opacity .6s 1.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  a.header--down-arrow.active { 
    opacity: 1;
  }
  
  a.header--down-arrow img{
    width: 30px;
  }
  
  .header--title {
    display: block;
    max-width: 450px;
    width: 44%;
    margin: 0 auto 0;
    text-align: center;
    position: relative;
    z-index: 11;
    height: 100vh;
    top: 50%;
  }
  
  img.logo1, img.logo2, img.logo3{
    display: block;
    max-width: 250px;
    width: 44%;
    margin: 0 auto 0;
    text-align: center;
    position: relative;
    z-index: 11;
  }
  
  .main {
    float: left;
    clear: both;
    width: 100%;
    height: 100vh;
    display: table;
    position: relative;
    z-index: 11;
  }
  
  .downloadImage{
    width: 80px;
    display: block;
  }
  
  h1, h2{
    width: 90%;
    max-width: 900px;
    text-align: center;
    color: #fff;
    font-size: 4.2em;
    margin: 20px auto 50px;
    line-height: 1.15em;
    position: relative;
    font-weight: normal;
  }
  
  h1{
    margin: 20px auto 120px;
  }
  
  h2{
    font-size: 2.9em;
  }
  
  h1 a, h2 a{
    color: #fff;
    opacity: 1;
    -webkit-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
    text-align: center;
  }
  
  h1 a:hover, h2 a:hover{
    opacity: 0.6;
  }
  
  p{
    width: 80%;
    max-width: 560px;
    text-align: center;
    color: #ccc;
    font-size: 2em;
    margin: 20px auto 0;
    line-height: 1em;
    position: relative;
    font-weight: normal;
    line-height: 1.3em;
    opacity: 0.6;
  }
  
  /* Scroll down indicator (bouncing) */
  @-webkit-keyframes bounce {
    0%, 100% {
      -webkit-transform: translateY(0); }
     50% {
      -webkit-transform: translateY(-10px); } 
  }
  
  @keyframes bounce {
    0%, 100% {
      -webkit-transform: translateY(0);
      transform: translateY(0); }
    50% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px); }
  }
  
  .background-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 10;
    background-color:transparent;	
  
    -webkit-transition: background-color .5s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: background-color .5s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .background-wrapper.active {
    background-color:transparent;	
  }
  
  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  @media screen and (max-width: 767px) {
  
    a.header--down-arrow {
      top: calc(100vh - 150px);
    
    }
    .header--title {
      top: calc(50% - 150px);
    }
  h1{
    margin: 20px auto 100px;
    font-size: 3em;
  }
  
  p, h2{
    font-size: 2.5em;
  }
  
  .fullscreen-bg{
      display:none;
  }
  
  
  h2{
    margin: 20px auto 40px;
  }
  
  
  a.downloadLink{
    background-size: 34px 34px;
    padding: 45px 0 0 0;
  }
  
  
  } /* end (max-width: 767px)  */
  
  @media screen and (max-width: 40em) {
  
  
  
  h1, h2{
    margin: 20px auto 70px;
    font-size: 2.8em;
    line-height: 1.2em;
  }
  
  
  p, h2{
    font-size: 2.4em;
  }
  
  h2{
    margin: 20px auto 30px;
  }	
  
  
  a.downloadLink{
    background-size: 28px 28px;
    padding: 38px 0 0 0;
  }
  
  
  
  } /* end (max-width: 40em)  */
  
  @media screen and (max-width: 36em) {
  
  h1, h2{
    font-size: 2.4em;
    margin: 20px auto 55px;
  }
  
  h2{
    font-size: 1.7em;
    margin: 20px auto 35px;
  }
  
  } /* end (max-width: 36em)  */
  
  
  .link-arrow {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); 
  }
  
  #logo-s {
    -webkit-transform: translate(-25%, 0%);
    transform: translate(-25%, 0%);
  
    -webkit-transition: all .6s .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: all .6s .4s cubic-bezier(0.25, 0.1, 0.25, 1);
  
    opacity: 0;
  }
  
  #logo-s.active {
    opacity: 1;
  
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  
  #logo-slash {
    -webkit-transform: translate(62%, -129%);
    transform: translate(62%, -129%);
  
    -webkit-transition: all .6s .7s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: all .6s .7s cubic-bezier(0.25, 0.1, 0.25, 1);
  
    opacity: 0;
  }
  
  #logo-slash.active {
    opacity: 1;
  
    -webkit-transform: translate(37%, 0%);
    transform: translate(37%, 0%);
  }
  
  #logo-b {
    -webkit-transform: translate(81%, 0%);
    transform: translate(81%, 0%);
  
    -webkit-transition: all .6s .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: all .6s .4s cubic-bezier(0.25, 0.1, 0.25, 1);
  
    opacity: 0;
  }
  
  #logo-b.active {
    opacity: 1;
  
    -webkit-transform: translate(64%, 0%);
    transform: translate(64%, 0%);
  }
  
  #Page-1 {
    -webkit-transform: scale(0.6) translateX(33%);
    transform: scale(0.6) translateX(33%);
  }
`

const defaultRowOptions = {
  mobile: { rows: 'auto-flow', gutter: 0},
  desktop: { rows: 'auto-flow', gutter: 0},
}

//@ts-ignore
export const createGrid = ({ columns, rows } = {}) => {
  const { mobile, desktop } = columns ? columns : defaultGridOptions
  const _rows = rows ? rows : defaultRowOptions

  return css`
    display: grid;
    width: 100%;
    grid: ${_rows.mobile.rows} / repeat(${mobile.columns}, ${getRelative(mobile.columnWidth, 'mobile', true)});
    column-gap: ${getRelative(mobile.gutterWidth, 'mobile', true)};
    row-gap: ${getRelative(_rows.mobile.gutter, 'mobile', true)};
    justify-content: center;

    ${mediaQuery.greaterThan('tablet')`
      grid: ${_rows.desktop.rows} / repeat(${desktop.columns}, ${getRelative(desktop.columnWidth, 'desktop', true)});
      column-gap:${getRelative(desktop.gutterWidth, 'desktop', true)};
      row-gap:${getRelative(_rows.desktop.gutter, 'desktop', true)};
    `}
  `
}

export const Reset = withGlobalStyle(theme, extraGlobalCss)

export const createUnderline = ({ reverse = false, color = 'black'} = {}) => css`
  position: relative;

  span {
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 0;
    background-color: ${({ theme }) => theme.colors[color]};
    left: unset;
    right: 0;
    transition: width 300ms ease, left 300ms ease, right 300ms ease;
  }

  ${reverse && `
    span {
      left: 0;
      right: unset;
      width: 100%;
    }
  `}

  &:hover,
  &.active {
    ${reverse ? `
      span {
        left: unset;
        right: 0;
        width: 0;
      }` : `
      span {
        left: 0;
        right: unset;
        width: 100%;
      }`
    }
  }
`
